<template>
  <div>
    <h1>Demo Component Library</h1>
    <hr />
    <h3>Card Selection</h3>

    <activation-flow-info />
    <hr />
    <h3>Card Selection</h3>
    <card-selection label="some label" has-application :card-list="[]" context="purchase"></card-selection>
    <card-selection has-application :card-list="[]" context="activation"></card-selection>

    <card-selection
      has-application
      :card-list="[
        { expiration: new Date().toISOString(), card_program_type: 'instant', status: 'active', public_token: '003422544', masked_pan: '2345********2342' },
        { expiration: new Date().toISOString(), card_program_type: 'instant', status: 'pending_activation', public_token: '003422544', masked_pan: '2345********2342' },
        { expiration: new Date().toISOString(), card_program_type: 'instant', status: 'expired', public_token: '003422544', masked_pan: '2345********2342' },
        { expiration: new Date().toISOString(), card_program_type: 'instant', status: 'lost_stolen', public_token: '003422544', masked_pan: '2345********2342' },
        { expiration: new Date().toISOString(), card_program_type: 'gpr', status: 'active', public_token: '003422544', masked_pan: '2345********2342' },
        { expiration: new Date().toISOString(), card_program_type: 'gpr', status: 'expired', public_token: '003422544', masked_pan: '2345********2342' },
        { expiration: new Date().toISOString(), card_program_type: 'gpr', status: 'lost_stolen', public_token: '003422544', masked_pan: '2345********2342' },
      ]"
      context="purchase"
    ></card-selection>
    <card-selection
      has-application
      :card-list="[
        { expiration: new Date().toISOString(), card_program_type: 'instant', status: 'active', public_token: '003422544', masked_pan: '2345********2342' },
        { expiration: new Date().toISOString(), card_program_type: 'instant', status: 'expired', public_token: '003422544', masked_pan: '2345********2342' },
        { expiration: new Date().toISOString(), card_program_type: 'instant', status: 'lost_stolen', public_token: '003422544', masked_pan: '2345********2342' },
        { expiration: new Date().toISOString(), card_program_type: 'gpr', status: 'active', public_token: '003422544', masked_pan: '2345********2342' },
        { expiration: new Date().toISOString(), card_program_type: 'gpr', status: 'expired', public_token: '003422544', masked_pan: '2345********2342' },
        { expiration: new Date().toISOString(), card_program_type: 'gpr', status: 'lost_stolen', public_token: '003422544', masked_pan: '2345********2342' },
      ]"
      context="activation"
    ></card-selection>

    <div :style="`--color-branding: ${testBrandingColor}; --color-branding-rgb-values: ${rgbBrandingColor}`">
      <consumer-gift-card-buy-activate-info />
    </div>
    <h3>consumer-gift-card-carousel</h3>
    <consumer-gift-card-carousel
      :images-urls="[
        'https://images.pexels.com/photos/416160/pexels-photo-416160.jpeg?cs=srgb&dl=pexels-pixabay-416160.jpg&fm=jpg',
        'https://images.pexels.com/photos/45170/kittens-cat-cat-puppy-rush-45170.jpeg?cs=srgb&dl=pexels-pixabay-45170.jpg&fm=jpg',
        'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQBBngC8PEH4gWTB8nKHXtGs0tjYeraYSpvOQ&usqp=CAU',
        'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQBBngC8PEH4gWTB8nKHXtGs0tjYeraYSpvOQ&usqp=CAU',
        'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQBBngC8PEH4gWTB8nKHXtGs0tjYeraYSpvOQ&usqp=CAU',
      ]"
    />
    <div :style="`--color-branding: ${testBrandingColor}; --color-branding-rgb-values: ${rgbBrandingColor}`">
      <h3>consumer-gift-card-quote-card</h3>
      <div style="display: flex; justify-content: space-around; flex-wrap: wrap; gap: var(--gap)">
        <consumer-gift-card-quote-card
          style="width: 387px"
          quote="J'ai adoré recevoir cette carte-cadeau propulsée par Hello, j'ai même pu bénéficier d'offres promotionnelles pour augmenter la valeur de ma carte!"
          client-name="Olivier Lalonde"
          client-description="bénéficiaire d’une carte-cadeau J’achète Laurier"
        />
        <consumer-gift-card-quote-card style="width: 387px" quote="L’achat a été super simple et rapide. Mon copain a beaucoup aimé la carte-cadeau." client-name="Caroline Bui" client-description="cliente" />
      </div>
      <h3>consumer-gift-card-marketing-card</h3>
      <div style="display: flex; justify-content: space-around; flex-wrap: wrap; gap: var(--gap)">
        <consumer-gift-card-marketing-card style="width: 350px" title="L'achat local" text="Cette carte-cadeau est l’occasion parfaite de découvrir les commerçants et restaurateurs d’ici!" icon="mdi-store" />
        <consumer-gift-card-marketing-card
          style="width: 350px"
          title="La liberté de choisir"
          text="Donnez à vos proches la liberté de choisir parmi une variété de produits et de services offerts par nos commerçants de confiance."
          icon="mdi-cursor-pointer"
        />
        <consumer-gift-card-marketing-card
          style="width: 350px"
          title="Une expérience unique"
          text="En offrant cette carte, vous soutenez non seulement l’économie locale, mais vous offrez également une expérience unique à chacun."
          icon="mdi-diamond-stone"
        />
      </div>
      <h3>consumer-gift-card-amount-selector</h3>
      <consumer-gift-card-amount-selector :amounts="[50, 75, 100, 200, 'other']" />
      <br />
      <consumer-gift-card-amount-selector :amounts="findSuggestedAmount([25, 50, 75, 100, 150, 200, 500, 'other'], 0, 0)" />
      <br />
      <consumer-gift-card-amount-selector :amounts="findSuggestedAmount([25, 50, 75, 100, 150, 200, 500, 'other'], 50, 200)" />
    </div>
    <hr />

    <div :style="`--color-branding: ${testBrandingColor}; --color-branding-rgb-values: ${rgbBrandingColor}`">
      <h3>h-search-field</h3>
      <h-search-field></h-search-field>

      <h3>h-select-branding-color</h3>
      <h-select-branding-color v-model="destinationSelectList[0]" :items="destinationSelectList"></h-select-branding-color>

      <h3>destination-button-with-modal</h3>
      <destination-button-with-modal :item="destinationItems[0]"></destination-button-with-modal>

      <h3>destination-button-list-slider</h3>
      <div style="max-width: 590px">
        <destination-button-list-slider :items="destinationItems" />
      </div>
    </div>

    <h3>h-pay-and-get-editable-input</h3>
    <p class="mb-0">Test Amount {{ testAmount }}</p>
    <h-pay-and-get-editable-input label="testLabel" hint="this is an hint" v-model="testAmount"></h-pay-and-get-editable-input>

    <h3>h-pay-and-get-editable-input-decimal</h3>
    <p class="mb-0">Test Amount {{ testAmount2 }}</p>
    <h-pay-and-get-editable-input-decimal v-model="testAmount2" hint="this is an hint" />

    <account-info-bar-card :label-card="$t('components.account_info_bar_base.my_card')" :card="{ public_token: '123432543', status: 'active', is_renewable: true }" />
    <account-info-bar-card :label-card="$t('components.account_info_bar_base.my_card')" :card="{ public_token: '123432543', status: 'pending_activation', is_renewable: false }" />
    <account-info-bar-card :label-card="$t('components.account_info_bar_base.my_card')" :card="{ public_token: '123432543', status: 'active', is_renewable: false }" />

    <h3>Auth Info Box</h3>
    <auth-info-box></auth-info-box>
    <h3>HActionLayout</h3>
    <h-action-layout>
      <h-btn>One btn with lots and lots of text</h-btn>
      <h-btn outlined>Cancel</h-btn>
    </h-action-layout>
    <h3>HActionLayout Multiple (large) btns</h3>
    <h-action-layout>
      <h-btn large>Action</h-btn>
      <h-btn large error>Action2</h-btn>
      <h-btn large outlined>Cancel</h-btn>
    </h-action-layout>
    <h3>HActionLayout Single (large) btn</h3>
    <h-action-layout>
      <h-btn large>Action</h-btn>
    </h-action-layout>

    <div style="background-color: grey">
      <h2>Modals</h2>

      <h3>instant-card-all-set-modal</h3>
      <instant-card-all-set-modal v-model="allSetModalIsOpen" />
      <h-btn @click="allSetModalIsOpen = !allSetModalIsOpen">CUSTOM Open</h-btn>

      <h3>find-multiple-offer-instant-card-success-modal</h3>
      <h-btn @click="fmoicsm = !fmoicsm">CUSTOM Open</h-btn>
      <find-multiple-offer-instant-card-success-modal v-model="fmoicsm" />

      <h3>consumer-gift-card-send-by-email-modal</h3>

      <consumer-gift-card-send-by-email-modal>
        <template v-slot:activator="{ on, attrs }">
          <h-btn outlined large v-on="on" v-bind="attrs"><v-icon left>mdi-wallet-giftcard</v-icon>Open</h-btn>
        </template>
      </consumer-gift-card-send-by-email-modal>
      <h3>consumer-gift-card-terms-and-conditions-modal</h3>
      <consumer-gift-card-terms-and-conditions-modal />

      <h3>consumer-gift-card-download-pdf-modal</h3>
      <consumer-gift-card-download-pdf-modal>
        <template v-slot:activator="{ on, attrs }">
          <h-btn outlined large v-on="on" v-bind="attrs"><v-icon left>mdi-wallet-giftcard</v-icon>Open</h-btn>
        </template>
      </consumer-gift-card-download-pdf-modal>

      <h3>consumer-gift-card-info-modal</h3>
      <consumer-gift-card-info-modal :show-action="false" :large-link="true"></consumer-gift-card-info-modal>
      <consumer-gift-card-info-modal :show-action="false" :large-link="true" use-branding-color></consumer-gift-card-info-modal>
      <consumer-gift-card-info-modal :show-action="true" :large-link="true"></consumer-gift-card-info-modal>
      <consumer-gift-card-info-modal :show-action="true" :large-link="true" use-branding-color></consumer-gift-card-info-modal>
      <consumer-gift-card-info-modal :show-action="true" :large-link="false" use-branding-color></consumer-gift-card-info-modal>

      <h3>Activate Card Modal</h3>
      <activate-card-modal public-token="003422544"></activate-card-modal>

      <h3>Payment Delete Saved Card Modal</h3>
      <payment-delete-saved-card-modal :card="{ last4: '4322' }"></payment-delete-saved-card-modal>

      <h3>Renew Card Modal GPR</h3>
      <renew-card-modal public-token="003422544" card-program-type="gpr"></renew-card-modal>
      <h3>Renew Card Modal Instant</h3>
      <renew-card-modal public-token="003422544" card-program-type="instant"></renew-card-modal>

      <h3>Overlay Renew Card Modal GPR</h3>
      <overlay-renew-card-modal public-token="003422544" card-program-type="gpr"></overlay-renew-card-modal>

      <h3>Overlay Renew Card Modal instant</h3>
      <overlay-renew-card-modal public-token="003422544" card-program-type="instant"></overlay-renew-card-modal>

      <h3>Update Email Modal</h3>
      <update-email-modal></update-email-modal>

      <h3>Update Password Modal</h3>
      <update-password-modal></update-password-modal>

      <h3>Forget Password Modal</h3>
      <forgot-password-modal></forgot-password-modal>

      <h3>Register Modal</h3>
      <register-modal></register-modal>

      <h3>Offer Card Error Modal</h3>
      <h-btn @click="ocem = !ocem">CUSTOM Open</h-btn>
      <offer-card-error-modal v-model="ocem"></offer-card-error-modal>

      <h3>Corpo Company Modal</h3>
      <corpo-company-modal edit-mode></corpo-company-modal>
      <br />
      <corpo-company-modal></corpo-company-modal>

      <h3>Corpo Order Error Modal</h3>
      <h-btn @click="coem = !coem">CUSTOM Open</h-btn>
      <corpo-order-error-modal v-model="coem"></corpo-order-error-modal>

      <h3>Activate Card Error Modal</h3>
      <h-btn @click="acem = !acem">CUSTOM Open</h-btn>
      <activate-card-error-modal v-model="acem"></activate-card-error-modal>

      <h3>Activate Card Success Modal</h3>
      <h-btn @click="acsm = !acsm">CUSTOM Open</h-btn>
      <activate-card-success-modal pin="1234" :pin-error="false" v-model="acsm"></activate-card-success-modal>

      <h3>Find Card Error Modal</h3>
      <h-btn @click="fcem = !fcem">CUSTOM Open</h-btn>
      <find-card-error-modal error-title="My Error Title" error-text="some long description used for blah blah blah" v-model="fcem"></find-card-error-modal>

      <h3>Find Card Success Modal</h3>
      <h-btn @click="fcsm = !fcsm">CUSTOM Open</h-btn>
      <find-card-success-modal v-model="fcsm"></find-card-success-modal>

      <h3>Find Card Success Modal Without Voucher</h3>
      <h-btn @click="fcsmwv = !fcsmwv">CUSTOM Open</h-btn>
      <find-card-success-modal-without-voucher v-model="fcsmwv"></find-card-success-modal-without-voucher>

      <h3>Overlay Request Card Reminder Modal</h3>
      <h-btn @click="openRequestCardReminderModal">CUSTOM Open</h-btn>
      <overlay-request-card-reminder-modal></overlay-request-card-reminder-modal>

      <h3>Pay And Get Consent Modal</h3>
      <h-btn @click="pagcm = !pagcm">CUSTOM Open</h-btn>
      <pay-and-get-consent-modal v-model="pagcm" branding_name="Demo Branding"></pay-and-get-consent-modal>

      <h3>First Account Corpo Business</h3>
      <h-btn @click="facb = !facb">CUSTOM Open</h-btn>
      <first-business-access-info-modal v-model="facb"></first-business-access-info-modal>

      <h3>Manage Subscription Modal</h3>
      <manage-subscription-modal></manage-subscription-modal>

      <h3>RestrictedToMembersModal</h3>
      <h-btn @click="openRestrictedToMembersModal">CUSTOM Open</h-btn>
      <restricted-to-members-modal></restricted-to-members-modal>

      <h3>SubscriptionWarningModal</h3>
      <h-btn @click="openSubscriptionWarningModal">CUSTOM Open</h-btn>
      <subscription-warning-modal></subscription-warning-modal>
      <h3>UnsubscribeModal</h3>
      <unsubscribe-modal></unsubscribe-modal>`
    </div>
    <hr class="mt-5" />

    <h3>restricted-to-member-badge</h3>
    <restricted-to-members-badge />

    <h3>account-email-pending-verification</h3>
    <account-email-pending-verification />

    <h3>account-card-required</h3>
    <account-card-required :redirect-link="{ url: 'the-url', label_fr: 'a-french-label', label_en: 'an-english-label' }" />

    <h3>h-hero-banner-partner</h3>
    <h-hero-banner-partner />

    <h3>h-hero-banner-big</h3>
    <h-hero-banner-big />

    <h3>h-step-header</h3>
    <h-step-header />

    <h3>h-subsection-header</h3>
    <h-subsection-header />

    <h3>h-error-box</h3>
    <h-error-box />

    <h3>h-success-box</h3>
    <h-success-box />

    <h3>h-dialog</h3>
    <div class="d-flex flex-column align-start">
      <div class="mb-2">
        <h-btn @click="showHDialog = true">H-Dialog without activator</h-btn>
        <h-dialog v-model="showHDialog" @close="closeFunction" title="H-Dialog without activator">
          <div>This dialog has no activator and it's v-model is managed by the parent component.</div>
        </h-dialog>
      </div>

      <div class="mb-2">
        <h-dialog title="H-Dialog with activator">
          <template v-slot:activator="{ on, attrs }">
            <h-btn v-on="on" v-bind="attrs">With activator</h-btn>
          </template>

          <p>This dialog has it's own activator and manages it's own visibility.</p>
        </h-dialog>
      </div>

      <div class="mb-2">
        <h-dialog title="H-Dialog with success prop" success>
          <template v-slot:activator="{ on, attrs }">
            <h-btn v-on="on" v-bind="attrs" color="success">With success prop</h-btn>
          </template>

          <p>This dialog has the "success" prop to modify it's styles.</p>
        </h-dialog>
      </div>

      <div class="mb-2">
        <h-dialog title="H-Dialog with warning prop" warning>
          <template v-slot:activator="{ on, attrs }">
            <h-btn v-on="on" v-bind="attrs" color="warning">With warning prop</h-btn>
          </template>

          <p>This dialog has the "warning" prop to modify it's styles.</p>
        </h-dialog>
      </div>

      <div class="mb-2">
        <h-dialog title="H-Dialog with error prop" error>
          <template v-slot:activator="{ on, attrs }">
            <h-btn v-on="on" v-bind="attrs" color="error">With error prop</h-btn>
          </template>

          <p>This dialog has the "error" prop to modify it's styles.</p>
        </h-dialog>
      </div>

      <h-dialog title="H-Dialog with icon" icon="mdi-alert" error>
        <template v-slot:activator="{ on, attrs }">
          <h-btn v-on="on" v-bind="attrs">With icon prop</h-btn>
        </template>

        <p>Look, an icon!</p>
      </h-dialog>

      <h-btn @click="showDeleteCardDialog = true">Delete card</h-btn>
      <h-dialog v-model="showDeleteCardDialog" @close="closeFunction" title="H-Dialog without activator">
        <div class="delete-card-modal">
          <p>Your are about deleting a card do you really want to process?</p>
          <div class="actions">
            <h-btn @click="closeDeleteCardDialog">Close</h-btn>
            <h-btn @click="closeDeleteCardDialog">Confirm</h-btn>
          </div>
        </div>
      </h-dialog>
    </div>

    <h-btn @click="testStateIsOpen = !testStateIsOpen">OPEN ACTION MODAL WITH v-model</h-btn>

    <h-dialog v-model="testStateIsOpen" @close="closeFunction" icon="mdi-alert" error title="H-Dialog without activator">
      <div class="delete-card-modal">
        <p>Your are about deleting a card do you really want to process?</p>
      </div>

      <template #action-footer>
        <h-btn @click="testStateIsOpen = !testStateIsOpen">OK</h-btn>
        <h-btn outlined>wow</h-btn>
      </template>
    </h-dialog>

    <h-dialog @close="closeFunction" title="H-Dialog without activator">
      <template v-slot:activator="{ on, attrs }">
        <h-btn v-on="on" v-bind="attrs" color="error">h-dialog action-footer 2</h-btn>
      </template>
      <div class="delete-card-modal">
        <p>Your are about deleting a card do you really want to process?</p>
      </div>
      <template #action-footer>
        <h-btn color="error" style="margin-left: auto">NICE</h-btn>
      </template>
    </h-dialog>

    <h-dialog @close="closeFunction" title="H-Dialog without activator">
      <template v-slot:activator="{ on, attrs }">
        <h-btn v-on="on" v-bind="attrs" color="error">h-dialog action-footer 3</h-btn>
      </template>
      <div class="delete-card-modal">
        <p>Your are about deleting a card do you really want to process?</p>
      </div>

      <template #action-footer>
        <h-btn color="warning" @click="doMyAction">btn action!</h-btn>
        <h-btn color="warning" outlined @click="doMyCancel">CANCEL btn!</h-btn>
      </template>
    </h-dialog>

    <h-dialog @close="closeFunction" title="H-Dialog without activator">
      <template v-slot:activator="{ on, attrs }">
        <h-btn v-on="on" v-bind="attrs" color="error">h-dialog with action-footer 4!</h-btn>
      </template>
      <div class="delete-card-modal">
        <p>Your are about deleting a card do you really want to process?</p>
      </div>
    </h-dialog>

    <h3>hello-form-error</h3>
    <hello-form-error class="error-box" />
    <v-btn @click="formError">Toggle Form Error</v-btn>
    <v-btn @click="clearError">Clear</v-btn>

    <h3>hello-overlay-error</h3>
    <hello-overlay-error />
    <v-btn @click="overlayError">Toggle Overlay Error</v-btn>
    <v-btn @click="clearError">Clear</v-btn>

    <h3>h-label</h3>
    <h-label />

    <h3>h-pay-and-get-label</h3>
    <h-pay-and-get-label />

    <h3>pay-and-get-offer-payment</h3>
    <pay-and-get-offer-payment />

    <h3>h-pay-and-get-input</h3>
    <h-pay-and-get-input />

    <h3>h-chip-small</h3>
    <div class="d-flex">
      <h-chip-small />
      <h-chip-small disable-plus disable-minus />
    </div>

    <h3>h-chip-big</h3>
    <div class="d-flex flex-wrap">
      <h-chip-big />
      <h-chip-big disable-plus disable-minus />
      <h-chip-big compact />
    </div>

    <h3>h-progress-bar</h3>
    <h-progress-bar />

    <h3>success-instant-card-application</h3>
    <success-instant-card-application :redirect-link="{ url: 'a-url', label_en: 'label-en', label_fr: 'label-fr' }" />

    <h3>overlay-request-card</h3>
    <overlay-request-card :redirect-link="{ url: 'a-url', label_en: 'label-en', label_fr: 'label-fr' }" />

    <h3>overlay-renew-card</h3>
    <overlay-renew-card :current-card="{ public_token: 'p-token', card_program_type: 'gpr', status: 'expired', is_renewable: false, expiration: '2023-07-31T23:59:59.999Z' }" />

    <h3>app-store-badges</h3>
    <app-store-badges />

    <h3>overlay-app</h3>
    <overlay-app />

    <h3>overlay-app with "large" prop (diff on md breakpoint)</h3>
    <overlay-app large />

    <h3>offer-balance-card</h3>
    <offer-balance-card :balance="30" />
    <offer-balance-card :balance="42.11" :promoter-image-src="'http://localhost:8085/img/default_space_banner.png'" />

    <h3>Custom Stepper</h3>
    <v-stepper v-model="stepperModel">
      <h-stepper-header>
        <h-stepper-step step="1" :complete="stepperModel > 1">Step 1</h-stepper-step>
        <v-divider />
        <h-stepper-step step="2" :complete="stepperModel > 2">Step 2</h-stepper-step>
        <v-divider />
        <h-stepper-step step="3" :complete="stepperModel > 3">Step 3</h-stepper-step>
      </h-stepper-header>

      <v-stepper-items class="text-center">
        <v-stepper-content step="1">
          <h2 class="mb-4">Step 1 Header</h2>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute
            irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
          </p>
          <h-btn @click="stepperModel++">Next</h-btn>
        </v-stepper-content>

        <v-stepper-content step="2">
          <h2 class="mb-4">Step 2 Header</h2>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute
            irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
          </p>
          <h-btn @click="stepperModel--" class="mr-4">Previous</h-btn>
          <h-btn @click="stepperModel++">Next</h-btn>
        </v-stepper-content>

        <v-stepper-content step="3">
          <h2 class="mb-4">Step 3 Header</h2>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute
            irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
          </p>
          <h-btn @click="stepperModel--">Previous</h-btn>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>

    <h3>auth-and-register</h3>
    <auth-and-register />

    <h3>swap-auth-and-register</h3>
    <swap-auth-and-register />

    <h3>classic-card-summary</h3>
    <card-summary-mobile type="classic" :properties="classicProperties" @choose="() => {}" />

    <h3>classic-card-summary</h3>
    <card-summary-mobile type="instant" :properties="instantProperties" @choose="() => {}" />

    <h3>card-summary-desktop</h3>
    <card-summary-desktop v-if="$vuetify.breakpoint.mdAndUp" :classic-properties="classicProperties" :instant-properties="instantProperties" :context-ready="true" />

    <h3>category-filter-bar (with branding color "{{ testBrandingColor }}" in this example)</h3>
    <v-color-picker v-model="testBrandingColor" dot-size="25" swatches-max-height="200"></v-color-picker>

    <div :style="`--color-branding: ${testBrandingColor}`">
      <h4 class="mt-4 mb-2">Light Mode</h4>
      <category-filter-bar :category-list="categoryList" />
      <h4 class="mt-4 mb-2">Dark Mode</h4>
      <category-filter-bar :category-list="categoryList" dark />
      <h4>h-label-box</h4>
      <h-label-box text="Eligible points of sale" :style="`--color-branding-rgb-values: ${rgbBrandingColor}`" />
    </div>

    <h3>payment-form</h3>
    <h-checkbox v-model="paymentIncludePostalCode" label="includePostalCode"></h-checkbox>
    <h-checkbox v-model="paymentIncludeCardName" label="includeCardName"></h-checkbox>
    <h-checkbox v-model="paymentHasPaymentMethods" label="hasPaymentMethods"></h-checkbox>
    <h-checkbox v-model="paymentAllowSaveCard" label="allowSaveCard"></h-checkbox>
    <payment-form-v5
      ref="paymentFormv5"
      :allow-save-card="paymentAllowSaveCard"
      :saved-card-function="() => {}"
      :has-payment-methods="paymentHasPaymentMethods"
      :include-postal-code="paymentIncludePostalCode"
      :include-card-name="paymentIncludeCardName"
      @shouldSaveCard="(val) => (shouldsavecard = val)"
      @readyToPay="(val) => (isreadytopay = val)"
    >
      <template v-slot:default="{ isReadyToPay, shouldSaveCard }">
        shouldSaveCard: {{ shouldSaveCard }}
        <h-btn block :loading="working" @click="$refs.paymentFormv5.submit()">{{ $t('components.pay_and_get_offer_payment.pay') }}</h-btn>
      </template>
    </payment-form-v5>
    <payment-form-v2
      ref="paymentFormv2"
      :allow-save-card="paymentAllowSaveCard"
      :saved-card-function="() => {}"
      :has-payment-methods="paymentHasPaymentMethods"
      :include-postal-code="paymentIncludePostalCode"
      :include-card-name="paymentIncludeCardName"
      @shouldSaveCard="(val) => (shouldsavecard = val)"
      @readyToPay="(val) => (isreadytopay = val)"
    >
      <template v-slot:default="{ isReadyToPay, shouldSaveCard }">
        shouldSaveCard: {{ shouldSaveCard }}
        <h-btn block :loading="working" @click="$refs.paymentFormv2.submit()">{{ $t('components.pay_and_get_offer_payment.pay') }}</h-btn>
      </template>
    </payment-form-v2>
    shouldSaveCard: {{ shouldsavecard }}
    <h-btn block :loading="working" :disabled="!isreadytopay" @click="() => {}">{{ $t('components.pay_and_get_offer_payment.pay') }}</h-btn>
  </div>
</template>
<script>
import safeExecute from '@/composables/safe-execute';
import OverlayRequestCard from '@/components/overlays/components/OverlayRequestCard';
import OverlayRenewCard from '@/components/overlays/components/OverlayRenewCard';
import { mapActions, mapGetters } from 'vuex';
import AppStoreBadges from '@/components/utilities/AppStoreBadges';
import AuthAndRegister from '@/components/authentication/AuthAndRegister';
import SwapAuthAndRegister from '@/components/authentication/SwapAuthAndRegister';
import AuthInfoBox from '@/components/authentication/AuthInfoBox';
import CategoryFilterBar from '@/components/search/bar/CategoryFilterBar';
import PaymentFormV5 from '@/components/pay-and-get/components/PaymentFormV5';
import PaymentFormV2 from '@/components/pay-and-get/components/PaymentFormV2';
import ActivateCardModal from '@/components/account/components/ActivateCardModal.vue';
import PaymentDeleteSavedCardModal from '@/components/pay-and-get/components/PaymentDeleteSavedCardModal.vue';
import FirstBusinessAccessInfoModal from '@/components/corpo/order-process/FirstBusinessAccessInfoModal.vue';
import RenewCardModal from '@/components/account/components/RenewCardModal.vue';
import HPayAndGetEditableInput from '@/components/system/inputs/HPayAndGetEditableInput.vue';
import ConsumerGiftCardInfoModal from '../consumer-gift-card/components/ConsumerGiftCardInfoModal.vue';
import AccountEmailPendingVerification from '@/components/account/AccountEmailPendingVerification.vue';
import AccountCardRequired from '@/components/account/AccountCardRequired.vue';
import PayAndGetOfferPayment from '@/components/pay-and-get/PayAndGetOfferPayment.vue';
import OverlayApp from '@/components/overlays/components/OverlayApp.vue';
import OfferBalanceCard from '@/components/instant-card-activation/OfferBalanceCard.vue';
import RestrictedToMembersBadge from '@/components/utilities/RestrictedToMembersBadge.vue';
import UpdateEmailModal from '@/components/account/components/UpdateEmailModal.vue';
import UpdatePasswordModal from '@/components/account/components/UpdatePasswordModal.vue';
import ForgotPasswordModal from '@/components/authentication/ForgotPasswordModal.vue';
import RegisterModal from '@/components/authentication/RegisterModal.vue';
import OfferCardErrorModal from '@/components/cards/components/OfferCardErrorModal.vue';
import CorpoCompanyModal from '@/components/corpo/order-process/CorpoCompanyModal.vue';
import CorpoOrderErrorModal from '@/components/corpo/order-process/CorpoOrderErrorModal.vue';
import ActivateCardErrorModal from '@/components/instant-card-activation/ActivateCardErrorModal.vue';
import ActivateCardSuccessModal from '@/components/instant-card-activation/ActivateCardSuccessModal.vue';
import FindCardErrorModal from '@/components/instant-card-activation/FindCardErrorModal.vue';
import FindCardSuccessModal from '@/components/instant-card-activation/FindCardSuccessModal.vue';
import FindCardSuccessModalWithoutVoucher from '@/components/instant-card-activation/FindCardSuccessModalWithoutVoucher.vue';
import OverlayRequestCardReminderModal from '@/components/overlays/components/OverlayRequestCardReminderModal.vue';
import PayAndGetConsentModal from '@/components/pay-and-get/PayAndGetConsentModal.vue';
import ManageSubscriptionModal from '@/components/subscription/ManageSubscriptionModal.vue';
import RestrictedToMembersModal from '@/components/subscription/RestrictedToMembersModal.vue';
import SubscriptionWarningModal from '@/components/subscription/SubscriptionWarningModal.vue';
import UnsubscribeModal from '@/components/subscription/UnsubscribeModal.vue';
import ConsumerGiftCardDownloadPdfModal from '../consumer-gift-card/components/ConsumerGiftCardDownloadPdfModal.vue';
import { convertColorToRgb } from '@/utils/color/color-helper';
import ConsumerGiftCardTermsAndConditionsModal from '../consumer-gift-card/components/ConsumerGiftCardTermsAndConditionsModal.vue';
import ConsumerGiftCardSendByEmailModal from '../consumer-gift-card/components/ConsumerGiftCardSendByEmailModal.vue';
import CardSummaryMobile from '@/components/account/components/CardSummaryMobile.vue';
import CardSummaryDesktop from '@/components/account/components/CardSummaryDesktop.vue';
import SuccessInstantCardApplication from '@/components/instant-card/components/SuccessInstantCardApplication.vue';
import FindMultipleOfferInstantCardSuccessModal from '@/components/instant-card-activation/FindMultipleOfferInstantCardSuccessModal.vue';
import AccountInfoBarCard from '@/components/account/AccountInfoBarCard.vue';
import InstantCardAllSetModal from '@/components/instant-card-activation/InstantCardAllSetModal.vue';
import ConsumerGiftCardQuoteCard from '@/components/consumer-gift-card/base-components/ConsumerGiftCardQuoteCard.vue';
import ConsumerGiftCardAmountSelector from '@/components/consumer-gift-card/base-components/ConsumerGiftCardAmountSelector.vue';
import ConsumerGiftCardMarketingCard from '../consumer-gift-card/base-components/ConsumerGiftCardMarketingCard.vue';
import OverlayRenewCardModal from '@/components/overlays/components/OverlayRenewCardModal.vue';
import ConsumerGiftCardCarousel from '@/components/consumer-gift-card/base-components/ConsumerGiftCardCarousel.vue';
import ConsumerGiftCardBuyActivateInfo from '../consumer-gift-card/base-components/ConsumerGiftCardBuyActivateInfo.vue';
import CardSelection from '@/components/pay-and-get/CardSelection.vue';
import { determineStatus, findSuggestedAmount } from '@/composables/offer-publisher-helper';
import DestinationButtonListSlider from '../destination/DestinationButtonListSlider.vue';
import DestinationButtonWithModal from '../destination/DestinationButtonWithModal.vue';
import HSelectBrandingColor from '../system/inputs/HSelectBrandingColor.vue';
import ActivationFlowInfo from '../instant-card-activation/ActivationFlowInfo.vue';

export default {
  setup(props, { root }) {
    const { execute, executeForm, executeTarget, working } = safeExecute(root.$store);
    return {
      execute,
      executeForm,
      executeTarget,
      working,
      findSuggestedAmount,
    };
  },
  components: {
    HSelectBrandingColor,
    DestinationButtonListSlider,
    DestinationButtonWithModal,
    SuccessInstantCardApplication,
    CardSummaryDesktop,
    CardSummaryMobile,
    ActivateCardModal,
    AccountEmailPendingVerification,
    AccountCardRequired,
    OverlayRequestCard,
    OverlayRenewCard,
    PayAndGetOfferPayment,
    OverlayApp,
    AppStoreBadges,
    OfferBalanceCard,
    AuthAndRegister,
    SwapAuthAndRegister,
    CategoryFilterBar,
    RestrictedToMembersBadge,
    PaymentDeleteSavedCardModal,
    FirstBusinessAccessInfoModal,
    RenewCardModal,
    UpdateEmailModal,
    UpdatePasswordModal,
    ForgotPasswordModal,
    RegisterModal,
    OfferCardErrorModal,
    CorpoCompanyModal,
    CorpoOrderErrorModal,
    ActivateCardErrorModal,
    ActivateCardSuccessModal,
    FindCardErrorModal,
    FindCardSuccessModal,
    FindCardSuccessModalWithoutVoucher,
    OverlayRequestCardReminderModal,
    PayAndGetConsentModal,
    ManageSubscriptionModal,
    RestrictedToMembersModal,
    SubscriptionWarningModal,
    UnsubscribeModal,
    HPayAndGetEditableInput,
    ConsumerGiftCardInfoModal,
    AuthInfoBox,
    ConsumerGiftCardDownloadPdfModal,
    ConsumerGiftCardTermsAndConditionsModal,
    ConsumerGiftCardSendByEmailModal,
    FindMultipleOfferInstantCardSuccessModal,
    AccountInfoBarCard,
    InstantCardAllSetModal,
    ConsumerGiftCardQuoteCard,
    ConsumerGiftCardAmountSelector,
    ConsumerGiftCardMarketingCard,
    ConsumerGiftCardCarousel,
    ConsumerGiftCardBuyActivateInfo,
    OverlayRenewCardModal,
    CardSelection,
    ActivationFlowInfo,
    PaymentFormV5,
    PaymentFormV2,
  },
  name: 'demo-view',
  title: (args) => args.$t('page_title.demo'),
  props: {
    space: String,
  },

  data() {
    return {
      selectedAmount: null,
      testAmount: 10,
      testAmount2: 10.22,
      allSetModalIsOpen: false,
      fmoicsm: false,
      ocem: false,
      coem: false,
      acem: false,
      acsm: false,
      fcem: false,
      fcsm: false,
      fcsmwv: false,
      pagcm: false,
      facb: false,
      testStateIsOpen: false,
      testBrandingColor: '#58c5c7',
      paymentIncludePostalCode: false,
      paymentHasPaymentMethods: false,
      paymentAllowSaveCard: false,
      paymentIncludeCardName: false,
      isreadytopay: false,
      shouldsavecard: false,
      helloTextInput: '',
      helloTextInputRules: [(value) => !!value || 'Required.', (v) => v.length >= 8 || 'Min 8 characters', () => `The email and password you entered don't match`],
      showHDialog: false,
      showDeleteCardDialog: false,
      showCardTypeSelectionModal: false,
      snackbar: false,
      text: 'Lorem ipsum dolor sit amet',
      vertical: true,
      destinationSelectList: ['Toutes les destinations', 'Brossard / Mall Champain', 'Gatineau / Galeries de Hull', 'Laval / Centropolis', 'Laval / Duo - Centre Laval', 'Montréal / Alexis Nihon', 'Montréal / Rockland', 'Québec / Promenates Beauport'],
      destinationItems: [
        {
          buttonText: 'Mall Champlain',
          buttonImageUrl: 'https://images.pexels.com/photos/416160/pexels-photo-416160.jpeg?cs=srgb&dl=pexels-pixabay-416160.jpg&fm=jpg',
          withWarning: true,
          modalTitle: 'Mall Champlain',
          modalText:
            'Monde inclusif d’exclusivités qui vous propose une expérience hors du commun! On s’y retrouve en famille, entre amis, entre collègues ou en amoureux pour partager les plaisirs de la vie : magasinage chic, expériences gourmandes, divertissements\n' +
            ' uniques et évènements incontournables.',
          modalAddress: '1799 Av. Pierre-Péladeau, Laval, QC, H7T 2Y5',
          modalImages: [
            'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQBBngC8PEH4gWTB8nKHXtGs0tjYeraYSpvOQ&usqp=CAU',
            'https://images.pexels.com/photos/45170/kittens-cat-cat-puppy-rush-45170.jpeg?cs=srgb&dl=pexels-pixabay-45170.jpg&fm=jpg',
            'https://images.pexels.com/photos/416160/pexels-photo-416160.jpeg?cs=srgb&dl=pexels-pixabay-416160.jpg&fm=jpg',
          ],
        },
        {
          buttonText: 'Galeries de Hull',
          buttonImageUrl: 'https://www.thetimes.co.uk/imageserver/image/%2Fmethode%2Fsundaytimes%2Fprod%2Fweb%2Fbin%2F33733f30-3697-11ed-9709-e13153ef454b.jpg?crop=3473%2C3473%2C868%2C0',
          modalTitle: 'Galeries de Hull',
          modalText: 'Modern mall offering 80 shops & a free Wi-Fi lounge, plus a food court & casual sit-down eateries.',
          modalAddress: '320 Boulevard Saint-Joseph, Gatineau, Quebec J8Y 3Y8',
          modalImages: ['https://cdn.pixabay.com/photo/2017/01/29/12/16/guinea-pig-2017678_1280.jpg', 'https://cdn.pixabay.com/photo/2014/01/11/23/40/guinea-pig-242520_1280.jpg', 'https://cdn.pixabay.com/photo/2017/03/15/12/54/piggy-2146099_1280.jpg'],
        },
        {
          buttonText: 'Centropolis',
          buttonImageUrl: 'https://cdn.pixabay.com/photo/2014/10/22/14/21/guinea-pig-498006_1280.jpg',
          modalTitle: 'Centropolis',
          modalText: 'Outdoor complex with varied stores & a large gourmet market, plus eateries, a cinema & mini-golf.',
          modalAddress: '1799 Av. Pierre-Péladeau, Laval, QC H7T 2Y5',
          modalImages: [
            'https://cdn.pixabay.com/photo/2017/06/17/12/25/guinea-pig-2412069_1280.jpg',
            'https://cdn.pixabay.com/photo/2014/10/22/14/21/guinea-pig-498006_1280.jpg',
            'https://cdn.pixabay.com/photo/2015/06/13/21/50/guinea-pig-808390_1280.jpg',
          ],
        },
        {
          buttonText: 'Alexis Nihon',
          buttonImageUrl: 'https://cdn.pixabay.com/photo/2024/01/15/21/16/dog-8510901_1280.jpg',
          modalTitle: 'Alexis Nihon',
          modalText:
            'Alexis Nihon Complex is a 223,000 m² building complexe situated at the border of Downtown Montreal, Quebec, Canada and the neighbouring city of Westmount, consisting of a shopping mall, two office towers, and a residential building.',
          modalAddress: '1500 Atwater Ave, Montreal, Quebec H3Z 1X5',
          modalImages: ['https://cdn.pixabay.com/photo/2024/01/15/21/16/dog-8510901_1280.jpg', 'https://cdn.pixabay.com/photo/2023/09/19/12/34/dog-8262506_1280.jpg', 'https://cdn.pixabay.com/photo/2023/09/04/06/59/dog-8232158_1280.jpg'],
        },
        {
          buttonText: 'Mall Champlain',
          buttonImageUrl: 'https://images.pexels.com/photos/416160/pexels-photo-416160.jpeg?cs=srgb&dl=pexels-pixabay-416160.jpg&fm=jpg',
          withWarning: true,
          modalTitle: 'Mall Champlain',
          modalText:
            'Monde inclusif d’exclusivités qui vous propose une expérience hors du commun! On s’y retrouve en famille, entre amis, entre collègues ou en amoureux pour partager les plaisirs de la vie : magasinage chic, expériences gourmandes, divertissements\n' +
            ' uniques et évènements incontournables.',
          modalAddress: '1799 Av. Pierre-Péladeau, Laval, QC, H7T 2Y5',
          modalImages: [
            'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQBBngC8PEH4gWTB8nKHXtGs0tjYeraYSpvOQ&usqp=CAU',
            'https://images.pexels.com/photos/45170/kittens-cat-cat-puppy-rush-45170.jpeg?cs=srgb&dl=pexels-pixabay-45170.jpg&fm=jpg',
            'https://images.pexels.com/photos/416160/pexels-photo-416160.jpeg?cs=srgb&dl=pexels-pixabay-416160.jpg&fm=jpg',
          ],
        },
        {
          buttonText: 'Galeries de Hull',
          buttonImageUrl: 'https://www.thetimes.co.uk/imageserver/image/%2Fmethode%2Fsundaytimes%2Fprod%2Fweb%2Fbin%2F33733f30-3697-11ed-9709-e13153ef454b.jpg?crop=3473%2C3473%2C868%2C0',
          modalTitle: 'Galeries de Hull',
          modalText: 'Modern mall offering 80 shops & a free Wi-Fi lounge, plus a food court & casual sit-down eateries.',
          modalAddress: '320 Boulevard Saint-Joseph, Gatineau, Quebec J8Y 3Y8',
          modalImages: ['https://cdn.pixabay.com/photo/2017/01/29/12/16/guinea-pig-2017678_1280.jpg', 'https://cdn.pixabay.com/photo/2014/01/11/23/40/guinea-pig-242520_1280.jpg', 'https://cdn.pixabay.com/photo/2017/03/15/12/54/piggy-2146099_1280.jpg'],
        },
        {
          buttonText: 'Centropolis',
          buttonImageUrl: 'https://cdn.pixabay.com/photo/2014/10/22/14/21/guinea-pig-498006_1280.jpg',
          modalTitle: 'Centropolis',
          modalText: 'Outdoor complex with varied stores & a large gourmet market, plus eateries, a cinema & mini-golf.',
          modalAddress: '1799 Av. Pierre-Péladeau, Laval, QC H7T 2Y5',
          modalImages: [
            'https://cdn.pixabay.com/photo/2017/06/17/12/25/guinea-pig-2412069_1280.jpg',
            'https://cdn.pixabay.com/photo/2014/10/22/14/21/guinea-pig-498006_1280.jpg',
            'https://cdn.pixabay.com/photo/2015/06/13/21/50/guinea-pig-808390_1280.jpg',
          ],
        },
      ],
      items: [
        {
          src: 'https://cdn.vuetifyjs.com/images/carousel/squirrel.jpg',
        },
        {
          src: 'https://cdn.vuetifyjs.com/images/carousel/sky.jpg',
        },
        {
          src: 'https://cdn.vuetifyjs.com/images/carousel/bird.jpg',
        },
        {
          src: 'https://cdn.vuetifyjs.com/images/carousel/planet.jpg',
        },
      ],
      categoryList: ['mixed', 'leisure_getaways', 'restaurants_bars', 'home_cars', 'fashion_style'],
      stepperModel: 1,
      classicProperties: [
        {
          name: this.$t('components.card_type_selection_modal.headings.rechargeable'),
          value: 'Y',
        },
        {
          name: this.$t('components.card_type_selection_modal.headings.personalized'),
          value: 'Y',
        },
        {
          name: this.$t('components.card_type_selection_modal.headings.open_wallet'),
          value: 'Y',
          hint: this.$t('components.card_type_selection_modal.headings.open_wallet_hint'),
        },
        {
          name: this.$t('components.card_type_selection_modal.headings.pin'),
          value: 'Y',
        },
        {
          name: this.$t('components.card_type_selection_modal.headings.limit'),
          value: this.$options.filters.currencySymbol('23 000'),
        },
        {
          name: this.$t('components.card_type_selection_modal.headings.replacement_cost'),
          value: this.$options.filters.currencySymbol(5),
        },
        {
          name: this.$t('components.card_type_selection_modal.headings.shipping_time'),
          value: this.$t('components.card_type_selection_modal.shipping_time_value'),
        },
      ],
      instantProperties: [
        {
          name: this.$t('components.card_type_selection_modal.headings.rechargeable'),
          value: 'Y',
        },
        {
          name: this.$t('components.card_type_selection_modal.headings.personalized'),
          value: 'N',
        },
        {
          name: this.$t('components.card_type_selection_modal.headings.open_wallet'),
          value: 'N',
          hint: this.$t('components.card_type_selection_modal.headings.open_wallet_hint'),
        },
        {
          name: this.$t('components.card_type_selection_modal.headings.pin'),
          value: 'N',
        },
        {
          name: this.$t('components.card_type_selection_modal.headings.limit'),
          value: this.$options.filters.currencySymbol('1 000'),
        },
        {
          name: this.$t('components.card_type_selection_modal.headings.replacement_cost'),
          value: this.$options.filters.currencySymbol(10),
        },
        {
          name: this.$t('components.card_type_selection_modal.headings.shipping_time'),
          value: this.$t('components.card_type_selection_modal.shipping_time_value'),
        },
      ],
    };
  },
  watch: {
    currentLocale() {
      this.$title = this.$t('page_title.offer_detail');
    },
  },
  computed: {
    ...mapGetters('space', ['currentSpaceSlug']),
    ...mapGetters('ui', ['currentLocale']),
    rgbBrandingColor() {
      const { rgbString } = convertColorToRgb(this.testBrandingColor);
      return rgbString;
    },
  },
  methods: {
    ...mapActions('overlay', ['openRequestCardReminderModal', 'openSubscriptionWarningModal', 'openRestrictedToMembersModal']),
    ...mapActions('error', ['clearError', 'addError', 'useFormError', 'useOverlayError']),
    async createClassicCardApplicationAndOpenNewTab() {
      await this.executeForm({
        action: 'account/createCardApplicationContext',
        success: (context) => {
          switch (context.result_code) {
            case 'application':
              window.open(this.$urlProvider.GenerateHelloApplyUrl(context.partner_id, context.context, this.currentLocale), '_self').focus();
              break;
            default:
              this.title = this.$t('components.overlay_request_card.apply_url_error_modal_title');
              this.text = this.$t('components.overlay_request_card.apply_url_error_modal_message');
              this.modalIsOpen = true;
              this.routeToMyAccount = true;
          }
        },
      });
    },
    backSpace() {
      this.$router.pushNoDuplicate({ name: 'spaceHome', params: { space: this.currentSpaceSlug } });
    },
    doMyAction() {
      // eslint-disable-next-line no-console
      console.log('My action is wonderfull!');
    },
    doMyCancel() {
      // eslint-disable-next-line no-console
      console.log('Oh boooo!');
    },
    formError() {
      this.clearError();
      this.useFormError();
      this.addError({ msg: 'Form Error', code: 'errors.app' });
    },
    overlayError() {
      this.clearError();
      this.useOverlayError();
      this.addError({ msg: 'Overlay Error', code: 'errors.app' });
    },
    closeFunction() {
      console.log('close function executed'); // eslint-disable-line no-console
    },
    closeDeleteCardDialog() {
      this.showDeleteCardDialog = false;
    },
  },
};
</script>

<style lang="scss" scoped>
h3 {
  margin: 20px 0 10px;
}
.error-box {
  margin-bottom: var(--gap);
}
.delete-card-modal {
  display: flex;
  flex-direction: column;
  gap: var(--gap);
}
.remove-card-action {
  margin-top: 8px;
}
.actions {
  display: flex;
  justify-content: space-between;
  gap: var(--gap);
}

.actions > * {
  flex-grow: 1;
}
</style>
