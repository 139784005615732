<template>
  <h-dialog v-bind="$attrs" v-on="$listeners" ref="dialog" :title="$t('components.pay_and_get_consent_modal.title')">
    <p>{{ $t('components.pay_and_get_consent_modal.sub_title', { space_label: branding_name }) }}</p>

    <ul>
      <li>{{ $t('components.pay_and_get_consent_modal.info_1') }}</li>
      <li>{{ $t('components.pay_and_get_consent_modal.info_2') }}</li>
      <li>{{ $t('components.pay_and_get_consent_modal.info_3', { space_label: branding_name }) }}</li>
    </ul>

    <template #action-footer>
      <h-btn @click="agree">{{ $t('components.pay_and_get_consent_modal.action') }}</h-btn>
    </template>
  </h-dialog>
</template>
<script>
export default {
  name: 'pay-and-get-consent-modal',
  props: {
    branding_name: {
      type: String,
      required: true,
    },
  },
  methods: {
    agree() {
      this.close();
      this.$emit('agree');
    },
    close() {
      this.$refs.dialog.close();
    },
  },
};
</script>
