<template>
  <h-form class="payment-form-v5">
    <div v-if="includeCardName" class="pay-and-get-offer-payment-stripe formRow">
      <stripe-like-card-name v-model="cardName" @change="onCardNameChange" class="field" />
    </div>
    <div id="card-container"></div>

    <slot v-bind:isReadyToPay="isReadyToPay" v-bind:shouldSaveCard="shouldSaveCard"></slot>
  </h-form>
</template>

<script>
import StripeLikeCardName from './StripeLikeCardName.vue';

export default {
  name: 'payment-form-v5',
  components: { StripeLikeCardName },
  props: {
    customId: {
      type: String,
      default: 'paymentForm',
    },
    hasPaymentMethods: {
      type: Boolean,
      default: true,
    },
    includePostalCode: {
      type: Boolean,
      default: false,
    },
    includeCardName: {
      type: Boolean,
      default: false,
    },
    allowSaveCard: {
      type: Boolean,
      default: true,
    },
    cardAlwaySaved: {
      type: Boolean,
      default: false,
    },
    savedCardFunction: {
      type: Function,
    },
  },
  async mounted() {
    this.card = await this.$square.card({
      style: {
        '.input-container': {
          borderColor: '#2e3191',
          borderWidth: '2px',
          borderRadius: '5px',
        },
        '.input-container.is-focus': {
          borderColor: '#2e3191',
        },
        '.input-container.is-error': {
          borderColor: '#2e3191',
        },
        '.message-text': {
          color: '#999999',
        },
        '.message-icon': {
          color: '#999999',
        },
        '.message-text.is-error': {
          color: '#ff1600',
        },
        '.message-icon.is-error': {
          color: '#ff1600',
        },
        input: {
          backgroundColor: 'transparent',
          color: '#32325D',
          fontFamily: 'Consolas, monospace',
        },
        'input::placeholder': {
          color: '#CFD7DF',
        },
        'input.is-error': {
          color: '#E25950',
        },
      },
    });
    await this.card.attach('#card-container');

    this.card.addEventListener('errorClassAdded', (event) => {
      if (event.detail.field === 'expirationDate') {
        this.cardExpiryIsReady = false;
      }
      if (event.detail.field === 'cardNumber') {
        this.cardNumberIsReady = false;
      }
      if (event.detail.field === 'cvv') {
        this.cardCvcIsReady = false;
      }
    });
    this.card.addEventListener('errorClassRemoved', (event) => {
      if (event.detail.field === 'expirationDate') {
        this.cardExpiryIsReady = true;
      }
      if (event.detail.field === 'cardNumber') {
        this.cardNumberIsReady = true;
      }
      if (event.detail.field === 'cvv') {
        this.cardCvcIsReady = true;
      }
    });
  },
  computed: {
    isReadyToPay() {
      let isReady = this.cardNumberIsReady && this.cardExpiryIsReady && this.cardCvcIsReady;
      if (this.includePostalCode) {
        isReady = isReady && this.cardZipIsReady;
      }
      if (this.includeCardName) {
        isReady = isReady && this.cardNameIsReady;
      }

      this.setReadyToPay(isReady);
      return isReady;
    },
  },
  data() {
    return {
      card: null,
      cardReadyToPay: false,
      shouldSaveCard: false,
      cardNumberIsReady: false,
      cardExpiryIsReady: false,
      cardCvcIsReady: false,
      cardZipIsReady: false,
      cardNameIsReady: false,
      cardNumber: null,
      cardExpiry: null,
      cardCvc: null,
      cardZip: '',
      cardName: '',
    };
  },
  methods: {
    async submit() {
      const tokenResult = await this.card.tokenize();
      if (tokenResult.status === 'OK') {
        // alert(tokenResult.token);
        return tokenResult.token;
      } else {
        if (tokenResult.errors) {
          throw new Error(tokenResult.errors[0].message);
        } else {
          throw new Error(`Tokenization failed with status: ${tokenResult.status}`);
        }
      }
    },
    setReadyToPay(val) {
      this.$emit('readyToPay', val);
    },
  },
};
</script>

<style lang="scss" scoped>
.payment-form-v5 {
  margin-top: var(--gap);
}

.formRow {
  display: flex;
  justify-content: space-between;
  flex: 0 0 auto;
  margin: var(--gap-small) 0;

  .pay-and-get-offer-payment-stripe {
    width: calc((100% - var(--gap-small) * 2) / 3);
  }
}

.pay-and-get-offer-payment-stripe {
  border: 2px solid var(--color-primary);
  box-sizing: border-box;
  border-radius: var(--border-radius);
  padding: var(--gap-small);
}

.field {
  height: 16px;
  width: 100%;
}
</style>
